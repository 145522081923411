import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Row, Col, Button, Card } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'; // or 'ant-design-vue/dist/antd.less'

createApp(App)
    .use(store)
    .use(router)
    .use(Row)
    .use(Col)
    .use(Button)
    .use(Card)
    .mount('#app')
