<template>
  <!-- <div class="segment txt-monospace">
    <h1 class="title txt-primary">
      Hello World
    </h1>

    <div class="stage">
      <div class="cube">
        <figure class="back"></figure>
        <figure class="top"></figure>
        <figure class="bottom"></figure>
        <figure class="left"></figure>
        <figure class="right"></figure>
        <figure class="front"></figure>
      </div>
    </div>
  </div> -->

  <div class="segment bg-secondary txt-monospace">
    <h1 class="title txt-white">
      Hello World
    </h1>

    <div class="scene">
      <div class="light">
        <div class="clip">
          <div class="container">
            <!-- X Axis -->
            <div class="circle border"></div>
            <div class="circle border"></div>
            <div class="circle border"></div>
            <div class="circle border"></div>
            <div class="circle border"></div>
            <!-- Y Axis -->
            <div class="circle border"></div>
            <div class="circle border"></div>
            <div class="circle border"></div>
            <div class="circle border"></div>
            <div class="circle border"></div>
            <!-- Z Axis -->
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="segment txt-monospace">
    <h1 class="title txt-primary">
      Hello World
    </h1>
    <a-row justify="center" :gutter="[{ xs: 8, sm: 16, md: 32, lg: 64 }]">
      <a-col :xs="20" :sm="8" :md="6" :lg="6" :xl="5" :xxl="4" :xxxl="3">
        <a-card hoverable class="card-primary left-card">
          <template #title>
            <span class="txt-white">Hello</span>
          </template>

          World
        </a-card>
      </a-col>

      <a-col :xs="20" :sm="8" :md="6" :lg="6" :xl="5" :xxl="4" :xxxl="3">
        <a-card hoverable class="card-primary middle-card">
          <template #title>
            <span class="txt-white">ဟယ်လို</span>
          </template>

          ကမ္ဘာကြီး
        </a-card>
      </a-col>

      <a-col :xs="20" :sm="8" :md="6" :lg="6" :xl="5" :xxl="4" :xxxl="3">
        <a-card hoverable class="card-default right-card">
          <template #title>
            <span class="txt-primary">say("Hello");</span>
          </template>

          toThe("World");
        </a-card>
      </a-col>
    </a-row>
  </div>

  <div class="segment bg-secondary txt-monospace">
    <h1 class="title txt-white">
      Hello World
    </h1>

    <a-row justify="center" :gutter="[{ xs: 8, sm: 16, md: 32, lg: 64 }]">
      <a-col :xs="20" :sm="8" :md="6" :lg="6" :xl="5" :xxl="4" :xxxl="3">
        <a-card hoverable class="card-default left-card-2">
          <template #title>
            <span class="txt-primary">Hello</span>
          </template>

          World
        </a-card>
      </a-col>

      <a-col :xs="20" :sm="8" :md="6" :lg="6" :xl="5" :xxl="4" :xxxl="3">
        <a-card hoverable class="card-default middle-card-2">
          <template #title>
            <span class="txt-primary">ဟယ်လို</span>
          </template>

          ကမ္ဘာကြီး
        </a-card>
      </a-col>

      <a-col :xs="20" :sm="8" :md="6" :lg="6" :xl="5" :xxl="4" :xxxl="3">
        <a-card hoverable class="card-primary right-card-2">
          <template #title>
            <span class="txt-white">say("Hello");</span>
          </template>

          toThe("World");
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',

  props: {
    msg: String
  },

  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

  $primary-bg-color: #4b7dbb;
  $secondary-bg-color: #151515;
  $primary-border-color: #2f5483;

  $card-border-thickness: 4px;
  $card-head-border-thickness: 5px;

  .segment {
    height: 100vh;
    max-height: 900px;
    padding: 3em 0;
    text-align: center;
    background: #f6f6f6; // default background
  }

  .bg-primary {
    background: $primary-bg-color;
  }

  .bg-secondary {
    background: $secondary-bg-color;
  }

  .title {
    font-weight: bold;
    margin-bottom: 2em;
  }

  .title span {
    background: #4747474f;
    padding: 0.5em 1em;
  }

  .txt-monospace {
    font-family: monospace;
  }

  .txt-primary {
    color: #1b2f49
  }

  .txt-white {
    color: #f8f8f8;
  }

  .ant-card {
    margin-bottom: 3em !important;
  }

  .card-primary {
    -webkit-box-reflect: below 0 -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(50%, transparent), to(RGBA(0, 0, 0, 0.1)));
    background: $primary-bg-color !important;
    color: #fff !important;
    border: $card-border-thickness solid $primary-border-color !important
  }

  .card-primary .ant-card-head {
    border-bottom: $card-head-border-thickness solid $primary-border-color !important;
  }

  .card-default {
    -webkit-box-reflect: below 0 -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(50%, transparent), to(RGBA(0, 0, 0, 0.1)));
    color: #1b2f49 !important;
    border: $card-border-thickness solid $primary-border-color !important;
  }

  .card-default .ant-card-head {
    border-bottom: $card-head-border-thickness solid $primary-border-color !important;
  }

  .ant-row {
    margin: 0 !important;
  }
</style>

<style scoped>
  .stage {
    margin: 0 auto;
    padding-top: 10em;
    width: 200px;
    height: 200px;
    perspective: 1000px;
    perspective-origin: 50% 150%;
  }

  .cube {
    transform-style: preserve-3d;
    animation: rotate 30s infinite linear;
  }

  figure {
    /* display: block; */
    position: absolute;
    width: 200px;
    height: 200px;
    background-color: #763d11;
    transform-origin: 50% 50% 0;
  }

  .front {
    transform: translateZ(100px);
  }

  .back {
    transform: rotateY(180deg) translateZ(100px);
  }

  .left {
    transform: rotateY(-90deg) translateZ(100px);
  }

  .right {
    transform: rotateY(90deg) translateZ(100px);
  }

  .top {
    transform: rotateX(90deg) translateZ(100px);
  }

  .bottom {
    transform: rotateX(-90deg) translateZ(100px);
  }

  @keyframes heads-up {
    from {
      perspective-origin: 50% -50%;
    }

    to {
      perspective-origin: 50% 150%;
    }
  }
  
  @keyframes rotate {
    0% {
      transform: rotateY(0);
    }

    100% {
      transform: rotateY(360deg);
    }
  }

  .bottom {
    box-shadow: 0 10px 100px #000000b3;
  }

  .front, .left, .right, .back {
    -webkit-box-reflect: below 0 -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(50%, transparent), to(RGBA(0, 0, 0, 0.1)));
  }

  .left-card {
    transform: perspective(200px) rotateY(10deg);
  }

  .left-card-2 {
    transform: perspective(200px) rotateY(-10deg);
  }

  .middle-card {
    transform: perspective(200px) translateZ(-20px);
  }

  .middle-card-2 {
    transform: perspective(200px) translateZ(20px);
  }

  .right-card {
    transform: perspective(200px) rotateY(-10deg);
  }

  .right-card-2 {
    transform: perspective(200px) rotateY(10deg);
  }

  @media only screen and (max-width: 576px) {
    .left-card, .left-card-2, .middle-card, .middle-card-2, .right-card, .right-card-2 {
      transform: perspective(200px) rotateY(0deg) !important;
    }
  }
</style>

<style scoped>
/* Rotate Sphere animation */
@keyframes animateSphere {
  0% { transform: rotateY(0deg) rotateX(0deg) rotateZ(0deg); }
  50% { transform: rotateY(360deg) rotateX(360deg) rotateZ(0deg); }
  100% { transform: rotateY(720deg) rotateX(720deg) rotateZ(360deg); }
}

.scene {
  perspective: 1000px;
  margin-top: 15em;
}

.container {
  margin-top: 5vh;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 200px;
  height: 200px;
  transform-style: preserve-3d;
  animation-name: animateSphere; 
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.border {
  border: 1px solid #003780;
}

.circle {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #0766da;
}

/* X Axis */
.circle:nth-child(1) {transform: rotate3d(1, 0, 0, 0deg);}
.circle:nth-child(2) {transform: rotate3d(1, 0, 0, 36deg);}
.circle:nth-child(3) {transform: rotate3d(1, 0, 0, 72deg);}
.circle:nth-child(4) {transform: rotate3d(1, 0, 0, 108deg);}
.circle:nth-child(5) {transform: rotate3d(1, 0, 0, 144deg);}
/* Y Axis */
.circle:nth-child(6) {transform: rotate3d(0, 1, 0, 0deg);}
.circle:nth-child(7) {transform: rotate3d(0, 1, 0, 36deg);}
.circle:nth-child(8) {transform: rotate3d(0, 1, 0, 72deg);}
.circle:nth-child(9) {transform: rotate3d(0, 1, 0, 108deg);}
.circle:nth-child(10) {transform: rotate3d(0, 1,0, 144deg);}
/* Z Axis */
.circle:nth-child(11) {transform: rotate3d(0, 1, 0, 90deg) rotate3d(1, 0, 0, 0deg);}
.circle:nth-child(12) {transform: rotate3d(0, 1, 0, 90deg) rotate3d(1, 0, 0, 36deg);}
.circle:nth-child(13) {transform: rotate3d(0, 1, 0, 90deg) rotate3d(1, 0, 0, 72deg);}
.circle:nth-child(14) {transform: rotate3d(0, 1, 0, 90deg) rotate3d(1, 0, 0, 108deg);}
.circle:nth-child(15) {transform: rotate3d(0, 1, 0, 90deg) rotate3d(1, 0, 0, 144deg);}

/* Light effect */
.light {
   margin: auto;
   border-radius: 50%;
   width: 200px;
   height: 200px;
  box-shadow: 0px 0px 60px #00bcff69;
} 
/* Clip the sphere a bit */
.clip {
  clip-path: circle(48%);
}
</style>